/* 
! Main Navigation Bar Style 
*/
.navBarStyle {
  overflow: hidden;
  height: 80px;
  background: #171717;
  display: flex;
  /* 
!  Navigation Bar Logo Style 
*/
  .navBarColStyle {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  /* 
!  Navigation Bar Dark Mode Style 
*/
  .navBarThemeToggleButtonText {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  /* 
!  Navigation Bar Theme Toggle Bar Style
*/
  .navBarThemeColToggleButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* 
!  Navigation Bar Account Navigator Style
*/
  .navBarThemeColAccountNavigator {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  /* 
!  Navigation Bar Drop Down Style
*/
  .navBarColDropDownNavigator {
    width: 10px;
    align-items: center;
    justify-content: center;
    margin: 30.3px 0px 0px 0px;
  }
}
