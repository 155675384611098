@mixin flexJustifyCenter {
  display: flex;
  justify-content: center;
}

@mixin imageSizeSupportPage {
  aspect-ratio: 4/2;
  object-fit: contain;
}
/* 
 ! Support Screen Style
 */
.supportScreenBackground {
  @include defaultPageStyle;

  /* 
  ! Support Screen Back Button Style
 */
  .backButtonSupportPage {
    box-sizing: border-box;
    display: flex;
    width: 194px;
    height: 48px;
    left: 88px;
    top: 48px;
    border: 2px solid #fafafa;
  }

  /* 
 ! Support Page Landing Image
 */
  .supportPageLandingImage {
    margin: 52px 0px 0px 0px;
    width: 100%;
    text-align: center;
    justify-content: center;
    .flexJustifyCenterSupportPage {
      @include flexJustifyCenter;
      .supportPageLogoStyle {
        width: 194px;
        height: 41px;
      }
    }
  }
  /* 
 ! Support Page Content Style
 */
  .supportPageContentStyle {
    margin: 48px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    .supportPageImgContent1 {
      @include imageSizeSupportPage;
    }
    .supportTitleStyle {
      margin: 16px 0px 0px 0px;
    }
    .supportButtonStyles {
      display: flex;
      justify-content: center;
      margin: 15px 0px 0px 0px;
    }

    .supportTechnicalDocImageStyle {
      @include imageSizeSupportPage;
    }
    .supportDiscordImageStyle {
      margin: 10px 0px 0px 0px;
      @include imageSizeSupportPage;
    }
  }
}
