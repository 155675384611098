/* 
* Profile Card main container
*/
.profileCardAvatarSkeleton {
  padding: 24px 0px 0px 24px;

  .profileCardContainer {
    display: flex;
    align-items: center;
  }
}

/* 
* Profile Card first subtitle
*/
.profileCardSubTitle1 {
  margin: 16px 0px 0px 0px;
}

/* 
* Profile Card second subtitle
*/
.profileCardSubTitle2 {
  margin: 16px 0px 0px 0px;
}
