/* 
* Data Card main container styling
*/
.dataCardMainRowStyle {
  display: flex;
  justify-content: center;
  align-items: center;

  /* 
* Main row for Avatar Icon
*/
  .dataCardMainRowStyleAvatarStyle1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 72px 0px 0px 0px;
  }

  /* 
* Main row for Avatar Icon
*/
  .dataCardMainRowStyleAvatarStyle2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0px 0px 0px;
  }

  /* 
* Selected Card Green tick
*/
  .dataCardGreenTick {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* 
* Avatar Icon styling
*/
  .dataCardAvatar {
    margin: 50px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* 
* Data card name styling
*/
  .dataCardText {
    margin: 72px 0px 0px 0px;
    display: flex;
    justify-content: center;
  }
}
