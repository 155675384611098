.loginInputFieldStyle {
  width: 408px;
  height: 56px;
  border: 2px solid #909090;
  box-sizing: border-box;

  .passwordIconStyle {
    display: flex;
    align-items: center;
  }
}
/* 
  * Switch css definition.
 */
.errorLoginInputFieldStyle {
  width: 408px;
  height: 56px;
  border: 2px solid #ea3d4a;
  box-sizing: border-box;

  .passwordIconStyle {
    display: flex;
    align-items: center;
  }
}

.formInputField {
  width: 428px;
  height: 40px;
  border: 2px solid #909090;
  box-sizing: border-box;
}
