@mixin defaultPageStyle {
  width: 100%;
  height: 100vh;
  background-color: #171717;
  overflow-y: auto;
}

.landingScreenBackground {
  @include defaultPageStyle;

  .landingSideBar {
    // NOTE: Replaced with px from rem due to button div leak
    // min-width: 600px;
    align-content: center;

    /* 
 ! Logo Design for landing Page 
*/
    .landingImage {
      margin: 3rem 0px 0px 0px;

      .logoStyle {
        width: 301px;
        height: 64px;
      }
    }

    /* 
  ! Logo Design for Cmate Account Page 
*/
    .loginCreateImage {
      margin: 32px 0px 0px 0px;

      .loginCreateContactUs {
        width: 0px 0px 0px 140px;

        .contactUsUnderline {
          margin: 8px 0px 0px 0px;
          width: 12px;
          height: 0px;
          border: 1rem solid #e1e1e1;
        }
      }
    }

    /* 
  ! Landing page 
*/

    .landingTitle {
      margin: 121px 0px 0px 0px;
    }

    .landingDescription {
      margin: 24px 0px 0px 0px;
    }

    .inputTextStyle1 {
      margin: 48px 0px 0px 0px;
    }

    .inputTextStyle2 {
      margin: 24px 0px 0px 0px;
    }

    .errorMessageRowStyle {
      margin: 10px 0px 0px 0px;

      .errorMessageIconStyle {
        padding: 0px 0px 0px 0px;
      }
      .errorMessageColoumnStyle {
        padding: 0px 0px 0px 0px;
        margin: 2px 0px 0px 0px;
      }
    }

    .radioButtonStyle1 {
      margin: 56px 0px 0px 0px;
    }

    .radioButtonStyle2 {
      margin: 24px 0px 0px 0px;
    }

    .contactusMargin {
      margin: 16px 0px 0px 0px;
      width: 408px;
      text-align: center;
    }

    /* 
  ! OTP Verfification page 
*/

    .inputTextStyleOtp {
      margin: 64px 0px 0px 0px;
    }

    .resendOtpStyle {
      width: 408px;
      text-align: end;
      margin: 16px 0px 0px 0px;
    }

    .successOtpIcon {
      text-align: center;
      margin: 82.4px 0px 0px 0px;
    }

    .successOtpStyle {
      margin: 24px 0px 0px 0px;
      text-align: center;
    }

    .successOtpDescription {
      margin: 48px 0px 0px 0px;
      width: 408px;
    }

    .successOtpButtonMargin {
      margin: 72px 0px 0px 0px;
    }

    .otpButtonMargin {
      margin: 136px 0px 0px 0px;
    }

    .otpButtonMarginDisabled {
      margin: 300px 0px 0px 0px;
    }

    .otpCancelStyle {
      width: 408px;
      text-align: center;
      margin: 16px 0px 0px 0px;
    }
  }

  /* 
  ! Landing Page Main Image
*/

  .landingImageAlign {
    background-color: #232323;
    height: 100vh;
    justify-items: center;
    .landingMainImg {
      width: 100%;
      height: 80vh;
    }
  }
}

.loginButtonStyle {
  margin: 110px 0px 0px 0px;
}

.defaultLandingPageButtonStyle {
  margin: 64px 0px 0px 0px;
}
